import dayjs from 'dayjs';
import Link from 'next/link';
import router from 'next/router';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { supremeConfig } from '@/api/config';
import type { Giveaway, GiveawayComment } from '@/api/supreme-api-sdk/src';
import { GiveawayApi } from '@/api/supreme-api-sdk/src';
import { Button } from '@/components/Button';
import type { ComponentSectionsGiveaway } from '@/generated/graphql-types';
import { userApiClient } from '@/pages/_app';
import { Logo } from '@/templates/Logo';

import { SocialShare } from '../SocialShare/SocialShare';
import styles from './GiveawaySection.module.scss';

type Props = {
    data: ComponentSectionsGiveaway
};

const getColorByPlatform = (platform: string | undefined) => {
    switch (platform) {
        case 'latino':
            return '#DBF717';
        case 'supreme':
            return '#f73e00';
        case 'create':
            return '#1AF085';
        default:
            return '#ffffff';
    }
};

const GiveawaySection: FC<Props> = ({ data: { GiveawaySlug, SignupLink } }) => {
    const [giveAway, setGiveAway] = useState<Giveaway | null>(null);
    const [comments, setComments] = useState<GiveawayComment[]>([]);

    useEffect(() => {
        if (GiveawaySlug && GiveawaySlug !== '') {
            const api = new GiveawayApi(supremeConfig);
            api.getGiveawayBySlug(GiveawaySlug).then((res) => {
                if (res && res.data) {
                    setGiveAway(res.data);
                    api.getGiveawayComents(res.data.id).then((commentsData) => {
                        commentsData && commentsData.data && setComments(commentsData.data);
                    }).catch(() => { });
                }
            }).catch(() => { });
        }
    }, [GiveawaySlug]);

    const platformUrl = useMemo(() => `https://app.bpmsupreme.com/d/giveaway/${GiveawaySlug}`, [GiveawaySlug]);

    const signupUrl = useMemo(() => (SignupLink && SignupLink.data && SignupLink.data.attributes && SignupLink.data.attributes.slug && SignupLink.data.attributes.slug !== '' ? `/${SignupLink.data.attributes.slug}?redirect=${encodeURIComponent(platformUrl)}` : `/signup?redirect=${encodeURIComponent(platformUrl)}`), [platformUrl]);

    useEffect(() => {
        userApiClient.getMe().then(() => {
            location.href = platformUrl;
        }).catch(() => { });
    }, []);

    return (
        <>
            <div
                className={
                    'header relative z-10 flex flex-row justify-between p-8 md:p-16'
                }
            >
                <Link href="/">
                    <a>
                        <Logo width={191} height={40} showText={true} />
                    </a>
                </Link>
                <img
                    onClick={() => router.push('/')}
                    className={'cursor-pointer hover:opacity-80'}
                    src={'/assets/icons/popUpClose.svg'}
                    alt='Close'
                    width={45}
                    height={45}
                />
            </div>
            {giveAway && <div className={styles.main}
            >
                <div className='mb-[3.2rem]'>
                    <img
                        src={giveAway?.banner_image_url}
                        alt={giveAway?.title}
                        width={1200}
                        height={296}
                    />
                </div>
                <div className='grid grid-cols-1 gap-14 md:grid-cols-2'>
                    <div>
                        <h1 className='mb-[1.6rem] text-[3.2rem] font-bold leading-[4rem]'>{giveAway?.title}</h1>
                        <div className='mb-[1.6rem] text-[1.2rem] leading-[1.6rem]'>
                            <div className='font-[300]'>Submission Start Date</div>
                            <div className='font-[600]'>{dayjs(giveAway?.start_date).format('MMMM D, YYYY, hh:mma')}</div>
                        </div>
                        <div className='mb-[1.6rem] text-[1.2rem] leading-[1.6rem]'>
                            <div className='font-[300]'>Submission Deadline</div>
                            <div className='font-[600]'>{dayjs(giveAway?.entry_deadline).format('MMMM D, YYYY, hh:mma')}</div>
                        </div>
                        <div className='mb-[1.6rem] text-[1.2rem] leading-[1.6rem]'>
                            <div className='font-[300]'>Winner Announced</div>
                            <div className='font-[600]'>{dayjs(giveAway?.announcement).format('MMMM D, YYYY, hh:mma')}</div>
                        </div>
                        <Button onClick={() => { location.href = signupUrl; }} style={{ marginTop: '4rem' }}>Enter Giveaway</Button>
                    </div>
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: giveAway?.description ?? '' }} />
                        <div className='mt-[2.4rem] flex flex-row justify-between'>
                            <a style={{ color: getColorByPlatform(giveAway?.platform as any) }} target={'_blank'} href={giveAway?.terms_pdf_url} rel="noreferrer">See Terms & Conditions</a>

                            <div className='flex items-center ' style={{ color: getColorByPlatform(giveAway?.platform as any) }}>
                                <SocialShare title='Share' platforms={['facebook', 'twitter', 'linkedin', 'link', 'email']} url={window.location.href} platformColor={getColorByPlatform(giveAway?.platform as any)}
                                    icon={<svg className='mr-[1rem] inline-block' width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 2V4H12V13H2V4H3V2H0V15H14V2H11Z" fill={getColorByPlatform(giveAway?.platform as any)} />
                                        <path d="M6 10H8V4H10L7 0L4 4H6V10Z" fill={getColorByPlatform(giveAway?.platform as any)} />
                                    </svg>} /></div>
                        </div>
                    </div>

                </div>
                <div>
                    <h3 className='mt-[3.2rem] mb-[2.4rem] text-[2.4rem]'>Prizes</h3>
                    <div className={styles.wrapper}>
                        {/* <Carousel
                            initialWidth={95.5}
                            count={4}
                            margin={16}
                        > */}
                        <div className={styles.scroll}>{giveAway?.GiveawayPrizes.map((prize) => <div onClick={() => window.open(prize.link, '_blank')} className='mr-[1.6rem] w-[29.6rem] cursor-pointer bg-[#212121] p-[1.6rem]' key={prize.title}>
                            <div className='min-h-[29rem] text-[2.4rem]'>
                                <img className='mb-[1.6rem] block' src={prize.image_url} alt={prize.title} />
                                <div className='text-[1.4rem] font-[600] leading-[2rem]'>{prize.title}</div>
                                <div className=' text-[1.2rem] font-[300] leading-[1.6rem]' dangerouslySetInnerHTML={{ __html: prize.description }} />
                            </div>
                        </div>)}</div>
                        {/* </Carousel> */}
                    </div>
                </div>
                <div>
                    <h3 className='mt-[3.2rem] mb-[2.4rem] text-[2.4rem]'>Comments ({comments.length})</h3>
                    <div>
                        {comments.map((comment) => <div className='mb-[3.2rem] flex flex-row items-start border-b-[1px] border-[#2a2a2a] pb-[3.2rem] align-top' key={comment.id}>
                            {comment.user.profile_image_thumbnail_url && <img className='mr-[2.1rem] rounded-[1.6rem]' width={32} height={32} src={comment.user.profile_image_thumbnail_url} alt={comment.user.full_name} />}
                            {!comment.user.profile_image_thumbnail_url && <div className='mr-[2.1rem] rounded-[1.6rem]' style={{ width: 32, height: 32 }} />}
                            <div>
                                <div className='text-[1.4] font-[600] leading-[2rem]'>{comment.user.full_name}</div>
                                <div className='text-[1.4] mt-[0.5rem] font-[300] leading-[2rem] text-[#919191]'>{dayjs(comment.created_at).format('MMMM D, YYYY')}</div>
                                <div className='text-[1.2] mt-[1.6rem] font-[300] leading-[1.6rem]'>{comment.comment}</div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>}
        </>
    );
};

export default GiveawaySection;
