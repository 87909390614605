import FingerprintJS from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';

import type { CreateDevice } from './supreme-api-sdk/src';

export async function getDevice(): Promise<CreateDevice> {
    const getName = () => {
        const parser = new UAParser();
        const parts = [];
        if (parser.getDevice().vendor) {
            parts.push(parser.getDevice().vendor!);
        }
        if (parser.getOS().name) {
            parts.push(parser.getOS().name);
        }
        if (parser.getDevice().model) {
            parts.push(parser.getDevice().model!);
        }
        if (parser.getBrowser().name) {
            parts.push(parser.getBrowser().name!);
        }
        if (parts.length > 0) {
            return parts.join(' ');
        }
        return undefined;
    };

    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    const deviceToken = visitorId;
    return {
        app_type: 'web_landing',
        app_version: '2.0', // should come from package.json
        build_version: '1', // should come from package.json
        device_uuid: deviceToken,
        device_data_os: 'web',
        device_data_device_name: getName(),
        debug: process.env.NODE_ENV !== 'production', // or false, if not develop
        language: (window.navigator as any).userLanguage || window.navigator.language,
    };
}
