import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import NextImage from '@/components/image';
import type { ComponentSectionsPlatformsSelector } from '@/generated/graphql-types';
import { Logo } from '@/templates/Logo';

type IPlatSelectProps = {
    data: ComponentSectionsPlatformsSelector;
};

const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
};

const PlatformSelectorView = observer((props: IPlatSelectProps) => {
    const router = useRouter();
    const [currentHovered, setCurrentHovered] = useState<number>(-1);
    return (
        <>
            <div
                className={
                    'header relative z-10 flex flex-row justify-between p-8 md:p-16'
                }
            >
                <Link href="/">
                    <a>
                        <Logo width={191} height={40} showText={true} />
                    </a>
                </Link>
                <img
                    onClick={() => router.push('/')}
                    className={'cursor-pointer hover:opacity-80'}
                    src={'/assets/icons/popUpClose.svg'}
                    alt="Go back"
                    width={45}
                    height={45}
                />
            </div>
            <div
                className={
                    'main flex w-full flex-col items-center justify-center pt-[8.6rem]'
                }
            >
                <span className={'text-5xl leading-faqTitle'}>{props.data.Title}</span>
                <motion.div
                    className={'mt-[8.5rem] flex flex-col md:flex-row'}
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    {props.data.Platforms.map((platform: any, i: number) => (
                        <motion.div
                            variants={listItem}
                            key={`I-${i}`}
                            onMouseEnter={() => setCurrentHovered(i)}
                            onMouseLeave={() => setCurrentHovered(-1)}
                            style={{ backgroundColor: currentHovered === i ? platform.Background : '#181918' }}
                            className={'item relative mx-[0.5rem] mb-[2.0rem] max-w-[37.4rem]  p-[3rem] hover:text-black md:mb-0'}
                            onClick={() => window.open(platform.Link, '_self')}
                        >
                            <span
                                className={
                                    'block min-h-[6.4rem] max-w-[20.6rem] text-3xl-m leading-sectionTitleMobile'
                                }
                            >
                                {platform?.Title}
                            </span>
                            <p
                                className={
                                    'mt-[1.7rem] block min-h-[7.2rem] max-w-[21.1rem] text-lg leading-[2.4rem]'
                                }
                            >
                                {platform?.Description}
                            </p>
                            <div className={'mt-[8.6rem] block'}>
                                <NextImage
                                    src=""
                                    priority
                                    media={platform.Image}
                                    alt={platform?.Title}
                                    width={318}
                                    height={237}
                                />
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                <style jsx>
                    {`
            @media (max-width: 1280px) {
            }

            :global(.item) {
              cursor: pointer;
            }

            :global(.item:hover path) {
              fill: black;
            }

            :global(.main *) {
              transition: background 0.2s ease-in-out;
            }

          `}
                </style>
            </div>
        </>
    );
});

export { PlatformSelectorView };
